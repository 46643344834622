import React, { useContext, useEffect, useLayoutEffect, useState } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { io } from "socket.io-client"
import { login } from "./apicalls/auth/login"
import { OneLiners, oneLiners } from "./common/texts"
import { ActiveChat, Langs, UserClientData } from "./common/types"
import ChangePasswordPage from "./components/ChangePasswordPage/ChangePasswordPage"
import ChatRoomPageWrapper from "./components/ChatRoomPage/ChatRoomPageWrapper"
import CreateRoomPage from "./components/CreateRoomPage/CreateRoomPage"
import ChatRoomDestroyedPopup from "./components/common-components/ChatRoomDestroyedPopup"
import Notification from "./components/common-components/Notification"
import EmailActivationPage from "./components/EmailActivationPage/EmailActivationPage"
import HomePage from "./components/HomePage/HomePage"
import LandingPage from "./components/LandingPage/LandingPage"
import PreferencesPage from "./components/PreferencesPage/PreferencesPage"
import SearchPage from "./components/SearchPage/SearchPage"
import { APIBASEURL, shouldUseDummyPassword } from "./config"
import { HearnokNotification } from "./types"
import { getLang } from "./utils/functions"
import ConnectRoomPage from "components/ConnectRoomPage/ConnectRoomPage"

const GlobalContext = React.createContext<{
	getText: (text: keyof OneLiners) => string
	lang: Langs
	setLang: (l: Langs) => void
	setNotification: (n: HearnokNotification | null) => void
	ratingPopupOpen: boolean
	setRatingPopupOpen: (value: boolean) => void
}>({
	getText: (text) => "",
	lang: "hu",
	setLang: () => {},
	setNotification: () => {},
	ratingPopupOpen: false,
	setRatingPopupOpen: () => {},
})

const AppContext = React.createContext<{
	user: UserClientData
	setUser: (val: UserClientData | null) => void
	activeChat: ActiveChat | null
	setActiveChat: React.Dispatch<React.SetStateAction<ActiveChat | null>>
	backAction?: { backAction: (() => void) | undefined }
	setBackAction: (val: { backAction: (() => void) | undefined }) => void
}>({
	user: {} as UserClientData,
	setUser: () => {},
	activeChat: null,
	setBackAction: () => {},
	setActiveChat: () => {},
})

export const useGlobalContext = () => {
	return useContext(GlobalContext)
}

export const useAppContext = () => {
	return useContext(AppContext)
}

export const socket = io(APIBASEURL,{transports: ['websocket'], upgrade: false})
socket.on("response", (data) => console.log(data))

function App() {
	const [user, setUser] = useState<UserClientData | null>(null)
	const [notification, setNotification] = useState<HearnokNotification | null>(null)
	const [activeChat, setActiveChat] = useState<ActiveChat | null>(null)
	const [backAction, setBackAction] = useState<{ backAction: (() => void) | undefined }>({
		backAction: undefined,
	})
	const [accessGranted, setAccessGranted] = useState(false)
	const [lang, setLang] = useState<Langs>(() => getLang())
	const [ratingPopupOpen, setRatingPopupOpen] = useState(false)

	const getText = (textName: keyof OneLiners) => {
		return oneLiners[textName][lang]
	}

	useLayoutEffect(() => {
		if (!accessGranted) {
			if ("Hearnok123456" === localStorage.getItem("password")) setAccessGranted(true)
		}
	}, [accessGranted])

	const tryToLoginWithCookie = () => {
		login({ socketID: socket.id, userLang: lang }).then(({ status, payload }) => {
			if (status === "success" && payload) {
				setUser({ ...payload })
				console.log("Login success")
			}
		})
	}

	useEffect(() => {
		if (notification && !notification.disappearing) {
			const newNotification: HearnokNotification = { ...notification, disappearing: true }
			setTimeout(() => {
				setNotification(newNotification)
				setTimeout(() => setNotification(null), 500)
			}, 3000)
		}
	}, [notification])

	useEffect(() => {
		socket.on("connect", tryToLoginWithCookie)
		socket.on("active-chat-update", (data) => {
			console.log("active chat update")
			//let activeChatData = data as ActiveChat
			//let messages = activeChatData.messages
			//console.log(messages)

			setActiveChat(data)
		})
		socket.on("disconnect", (reason) => {
			console.log(reason)
			if (reason === "io server disconnect") {
				
				// the disconnection was initiated by the server, you need to reconnect manually
				socket.connect();
			}
				// else the socket will automatically try to reconnect
		  });
		/*socket.on("balance-update", (data) => {
			if (user && "balanceInMinutes" in user) setUser({ ...user, balanceInMinutes: data })
			if (data <= 0) setDueToDepletedBalance(true)
		})*/
	}, []) //eslint-disable-line react-hooks/exhaustive-deps

	const checkPassword = (data: React.FormEvent<HTMLFormElement>) => {
		data.preventDefault()
		const val = (data.target as any)["asd"].value
		if (val === "Hearnok123456") {
			setAccessGranted(true)
			localStorage.setItem("password", val)
		}
	}

	if (!accessGranted && shouldUseDummyPassword) {
		return (
			<div>
				<form onSubmit={(data) => checkPassword(data as any)}>
					<p style={{ color: "black" }}>Jelszó</p>
					<input name="asd" />
					<button type="submit">Oké</button>
				</form>
			</div>
		)
	}

	return (
		<GlobalContext.Provider value={{ getText, lang, setLang, setNotification, ratingPopupOpen, setRatingPopupOpen }}>
			{notification ? <Notification {...notification} setNotification={setNotification} /> : null}
			{!user ? (
				<Router>
					<Switch>
						<Route path="/activate">
							<EmailActivationPage />
						</Route>
						<Route path="/change-password">
							<ChangePasswordPage />
						</Route>
						<Route path="/">
							<LandingPage setUser={setUser} />
						</Route>
					</Switch>
				</Router>
			) : (
				<AppContext.Provider
					value={{
						user,
						setUser,
						activeChat,
						setBackAction,
						backAction,
						setActiveChat,
					}}
				>
					<Router 
					 getUserConfirmation={() => {
						/* Empty callback to block the default browser prompt */
					  }}
					  >
						{activeChat?.isDeleted === true && activeChat?.creatorID !== user.id ? (
							<ChatRoomDestroyedPopup
								popupMsg={getText("Room has been closed")}
								popupText = {getText("Room has been closed text")}
							/>
						) : null}
						<Route exact path="/preferences">
							<PreferencesPage />
						</Route>
						<Route exact path="/">
							<HomePage />
						</Route>
						<Route path="/rooms">
							<ChatRoomPageWrapper />
						</Route>
						<Route path="/create-room">
							<CreateRoomPage />
						</Route>
						<Route path="/search">
							<SearchPage />
						</Route>
						<Route path="/connect-room">
							<ConnectRoomPage />
						</Route>
						<Route path="/change-password">
							<ChangePasswordPage />
						</Route>
					</Router>
				</AppContext.Provider>
			)}
		</GlobalContext.Provider>
	)
}

export default App
